export default () => {
  const userAgent = ref<string | null>(null);
  if (process.server) {
    userAgent.value = useRequestEvent().node.req.headers["user-agent"] ?? null;
  } else {
    userAgent.value = navigator.userAgent;
  }

  const mobileOs = getMobileOS(userAgent);

  return { userAgent, mobileOs };
};

/**
 * Returns the mobile operating system. It can be "iOs" or "android".
 * If the os is not mobile, will return undefined.
 */
export const getMobileOS = (userAgent: Ref<string | null>) => {
  return computed(() => {
    if (!userAgent.value) return "unknown";
    if (/(iPhone|iPod|iPad)/.test(userAgent.value)) return "iOs";
    if (/Android/.test(userAgent.value)) return "android";
    return "unknown";
  });
};
